'use client'

import classnames from 'classnames'
import React, {type DetailedHTMLProps, type HTMLAttributes} from 'react'
import {usePathname} from 'next/navigation'
import styles from './styles.module.css'
import {internalLinks} from '~/routing/internal-links'
import MWDSLink from '~/mercuryWebCompat/design-system/MWDSLink'

// This component is the minimal version of the "mercury is not a bank disclaimer"
// Useful if you want to use different styling than what the default <Disclaimer />
// component provides
export const MercuryIsNotABankDisclaimer = () => {
  const pathname = usePathname()
  return (
    <>
      Mercury is a fintech company,{' '}
      {pathname?.startsWith(internalLinks.howItWorks) ? (
        'not an FDIC-insured bank'
      ) : (
        <MWDSLink
          analyticsEvent={{
            type: 'fem-disclaimer-how-it-works-link',
            location: 'disclaimer',
            text: 'not a bank',
            linkedPath: internalLinks.howItWorks,
          }}
          underline="always"
          href={internalLinks.howItWorks}
        >
          not an FDIC-insured bank
        </MWDSLink>
      )}
      . Banking services provided by Choice Financial Group and Evolve Bank & Trust
      {'\u00AE'}; Members FDIC. Deposit insurance covers the failure of an insured
      bank.
    </>
  )
}

type TDisclaimerProps = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'children'
> & {
  withATopBorder?: boolean
  shouldCenter?: boolean
}

// Disclaimer component w/ default styles
export default function Disclaimer({
  className,
  withATopBorder,
  shouldCenter,
  ...rest
}: TDisclaimerProps) {
  return (
    <div
      className={classnames(
        styles.wrapper,
        className,
        shouldCenter && styles.centered
      )}
      {...rest}
    >
      {withATopBorder && <hr className={styles.topBorder} />}
      <span className={styles.disclaimer}>
        <MercuryIsNotABankDisclaimer />
      </span>
    </div>
  )
}
